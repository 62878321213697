@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstNameRoot {
  width: calc(50% - 9px);
}

.lastNameRoot {
  width: calc(50% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.guestBottomWrapper {
  margin-top: 50px !important;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 10px;
  & > button {
    border: 1px solid;
  }
}

.newsletter {
  & .newsletterText {
    margin-bottom: 14px;
  }
}
.checkboxGroupField {
  & label {
    & span {
      font-weight: var(--fontWeightSemiBold);
      font-size: 14px;
    }
  }
}
input,
select,
textarea {
  background-color: var(--colorText);
}
